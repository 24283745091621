import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./Contact.scss";

import PageBanner from "./PageBanner/PageBanner";
import { Alert } from "react-bootstrap";
import aos from "aos";
import "aos/dist/aos.css";
import { db } from "../../firebase";

export default function Contact() {
  aos.init();
  useEffect(() => {
    document.title = " Contact | jposarekar";
  }, []);

  const [emailData, setemailData] = useState({
    name: "",
    email: "",
    msg: "",
  });

  const [success, setsuccess] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    const { name, email, project } = e.target.elements;

    setemailData({
      name: name.value,
      email: email.value,
      msg: project.value,
    });

    e.target.elements !== "" &&
      db.ref("projReq/").push({
        name: name.value,
        email: email.value,
        msg: project.value,
      });

    setsuccess(true);

    e.target.reset();
  }

  console.log(emailData);

  return (
    <>
      <section className="contact firstDiv">
        <Container>
          <h2 className="section__subtitle">let's talk</h2>

          <h2
            className="section__title col-md-4 p-0"
            data-aos="flip-up"
            data-aos-easing="ease-in-sine"
            data-aos-offset="300"
            data-aos-once="true"
          >
            Hello! I've been waiting for you.
          </h2>

          <h2 className="subheading mt-3">
            Fill in the form or&nbsp;
            <span>
              <a
                href="mailto:me@jposarekar.in?subject=jposarekar - Project Request"
                className="hlink"
              >
                Send me an email
              </a>
            </span>
          </h2>

          <form
            action=""
            id="contact__form"
            className="col-md-6 p-0 mt-5"
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              name="name"
              id="fname"
              className="form-control"
              placeholder="What's your name?"
              required
            />
            <input
              type="email"
              name="email"
              id="email"
              className="form-control"
              placeholder="Your Email"
              required
            />

            <textarea
              name="project"
              id="project"
              cols="30"
              rows="5"
              className="form-control"
              placeholder="Tell me about your project..."
              required
            ></textarea>

            <input type="submit" value="Send Email" className="sub__btn" />
          </form>

          {success === true ? (
            <Alert variant="jpSuccess mt-5">
              Thank You! {emailData.name}. <br /> Your project request submitted
              successfully 🎉
            </Alert>
          ) : (
            ""
          )}
        </Container>
      </section>

      <PageBanner />
    </>
  );
}
