import React, { useEffect } from "react";
import "./About.scss";

import AboutInfo from "./AboutInfo/AboutInfo";
import AboutApproach from "./AboutApproach/AboutApproach";
import AboutDocs from "./AboutDocs/AboutDocs";
import AboutBts from "./AboutBts/AboutBts";
import AboutDetails from "./AboutDetails/AboutDetails";

import aos from "aos";
import "aos/dist/aos.css";

export default function About(props) {
  aos.init();

  useEffect(() => {
    document.title = " About | jposarekar";
  }, []);

  return (
    <>
      <AboutInfo />
      <AboutApproach />
      <AboutBts />
      <AboutDetails {...props.aboutData} />
      <AboutDocs {...props.aboutData} />
    </>
  );
}
