import React from "react";
import { Container } from "react-bootstrap";
import "./AboutDocs.scss";

import Skeleton from "react-loading-skeleton";

export default function AboutDocs(props) {
  return (
    <>
      <section className="aboutDocs">
        <Container>
          <div
            className="titleHead"
            data-aos="flip-up"
            data-aos-easing="ease-in-sine"
            data-aos-offset="300"
            data-aos-once="true"
          >
            <h2 className="section__title">Download Documents</h2>

            <h2 className="subheading mt-2">Let's work together</h2>
          </div>

          <div className="aboutDocs__wrapper">
            <div className="docsBox resBox">
              <h2 className="heading-3">My Resume</h2>
              <h2 className="body-large">For recruitment purpose only</h2>

              <a
                href={
                  props.docLink !== undefined ? (
                    props.docLink[0].link
                  ) : (
                    <Skeleton width={100} />
                  )
                }
                download={
                  props.docLink !== undefined ? (
                    props.docLink[0].link
                  ) : (
                    <Skeleton width={100} />
                  )
                }
                className="bbtn"
              >
                Download Now
              </a>
            </div>

            <div className="docsBox visBox">
              <h2 className="heading-3">My Visiting Card</h2>
              <h2 className="body-large">For business purpose only</h2>

              <a
                href={
                  props.docLink !== undefined ? (
                    props.docLink[1].link
                  ) : (
                    <Skeleton width={100} />
                  )
                }
                download={
                  props.docLink !== undefined ? (
                    props.docLink[1].link
                  ) : (
                    <Skeleton width={100} />
                  )
                }
                className="bbtn"
              >
                Download Now
              </a>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
