import React, { useState, useEffect } from "react";
import "./App.scss";
import Routes from "./utils/routes";
import { db } from "./firebase";

// test

function App() {
  const [jpData, setjpData] = useState([]);

  useEffect(() => {
    let refs = db.ref("/");
    refs.on("value", (querySnapshot) => {
      setjpData(querySnapshot.val());
    });
  }, []);

  return (
    <div className="App">
      <Routes {...jpData} />
    </div>
  );
}

export default App;
