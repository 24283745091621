import { BrowserRouter as Router, Route } from "react-router-dom";
import history from "./history";
import React from "react";
import Home from "../pages/Home/Home";
import Work from "../pages/Work/Work";
import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import Project from "../pages/Project/Project";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "./ScrollToTop";

export default function Routes(props) {
  return (
    <>
      <Router history={history}>
        <ScrollToTop />
        <>
          <Header />
          <Route exact path="/" render={() => <Home {...props} />} />
          <Route path="/work" render={() => <Work {...props} />} />
          <Route path="/about" render={() => <About {...props} />} />
          <Route path="/contact" render={() => <Contact />} />
          <Route path="/project-jposarekar" render={() => <Project />} />
          <Footer />
        </>
      </Router>
    </>
  );
}
