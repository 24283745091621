import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./AboutInfo.scss";

import AbtBanner from "./AbtBanner/AbtBanner";

export default function AboutInfo() {
  return (
    <>
      <section className="aboutInfo firstDiv">
        <Container>
          <div
            className="titleHead"
            data-aos="flip-up"
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
          >
            <h2 className="section__subtitle">about me</h2>
            <h2 className="section__title">Jignesh Posarekar</h2>
          </div>
        </Container>

        <AbtBanner />

        <Container className="abtStory">
          <Row>
            <Col md={4}>
              <h2 className="section__title">My Story</h2>
            </Col>

            <Col md={8}>
              <h2 className="subheading">
                Graduated in <strong>Computer Engineering</strong> in 2019 and
                is obsessed with clean and minimal design guidelines. My
                objective is to provide the best user experience along with a
                beautiful user interface.
                <br />
                <br />A designer who can code and specializes in{" "}
                <strong>UX Engineering</strong>.
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
