import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import "./Work.scss";

import ProjBox from "../../components/ProjBox/ProjBox";
import aos from "aos";
import "aos/dist/aos.css";
import Skeleton from "react-loading-skeleton";

export default function Work(props) {
  aos.init();
  useEffect(() => {
    document.title = " Work | jposarekar";
  }, []);

  return (
    <>
      <section className="work firstDiv">
        <Container>
          <div
            className="titleHead"
            data-aos="flip-up"
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
          >
            <h2 className="section__subtitle">Selected projects</h2>
            <h2 className="section__title">Projects</h2>
          </div>

          <div className="work__wrapper">
            {props.workdata !== undefined ? (
              props.workdata.map(({ id, img, title, description }) => (
                <ProjBox
                  key={id}
                  img={img}
                  title={title}
                  description={description}
                />
              ))
            ) : (
              <>
                <Skeleton width={"100%"} height={300} />
                <Skeleton width={"100%"} height={300} />
                <Skeleton width={"100%"} height={300} />
                <Skeleton width={"100%"} height={300} />
                <Skeleton width={"100%"} height={300} />
                <Skeleton width={"100%"} height={300} />
                <Skeleton width={"100%"} height={300} />
                <Skeleton width={"100%"} height={300} />
                <Skeleton width={"100%"} height={300} />
                <Skeleton width={"100%"} height={300} />
              </>
            )}
          </div>
        </Container>
      </section>
    </>
  );
}
