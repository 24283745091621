import React from "react";
import { Container } from "react-bootstrap";
import "./AbtBanner.scss";

export default function AbtBanner() {
  const bannerStyle = {
    backgroundColor: "#5a21e8",
    backgroundImage: "url('img/brand/about__bc.png')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundBlendMode: "multiply",
    backgroundSize: "contain",
  };

  return (
    <div className="abtBanner">
      <div className="abtBackground" style={bannerStyle}></div>

      <Container>
        <div className="leftImg">
          <img
            src="img/brand/logo__white.png"
            alt="jposarekar"
            className="brandLogo"
            loading="lazy"
          />
        </div>

        <div className="rightImg">
          <img
            src="img/brand/jignesh.png"
            alt="jposarekar"
            className="brandPerson"
            loading="lazy"
          />
        </div>
      </Container>
    </div>
  );
}
