import React, { useEffect } from "react";
import "./Home.scss";

import HomeBanner from "./HomeBanner/HomeBanner";
import Partner from "./Partner/Partner";
import FeatWork from "./FeatWork/FeatWork";
import HomeProcess from "./HomeProcess/HomeProcess";

export default function Home(props) {
  useEffect(() => {
    document.title = "Jignesh Posarekar | UX Engineer";
  }, []);
  return (
    <>
      <HomeBanner />
      <FeatWork {...props} />
      <HomeProcess />
      <Partner />
    </>
  );
}
