import { Container } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import "./Project.scss";

import { db, auth } from "../../firebase";

export default function Project() {
  const [projData, setprojData] = useState([]);

  useEffect(() => {
    readData();
  }, []);

  const readData = () => {
    db.ref("projReq/").once("value", function (snapshot) {
      let user = [];
      snapshot.forEach(function (userSnapshot) {
        var userKey = userSnapshot.key;
        var userData = userSnapshot.val();
        user.push({
          id: userKey,
          name: userData.name,
          email: userData.email,
          msg: userData.msg,
        });

        setprojData(user);
      });
    });
  };

  const [user, setuser] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [emailError, setemailError] = useState("");
  const [passwordError, setpasswordError] = useState("");

  const clearInputs = () => {
    setemail("");
    setpassword("");
  };

  const clearError = () => {
    setemailError("");
    setpasswordError("");
  };

  const handleLogin = () => {
    clearError();
    auth.signInWithEmailAndPassword(email, password).catch((err) => {
      switch (err.code) {
        case "auth/invalid-email":
        case "auth/user-disabled":
        case "auth/user-not-found":
          setemailError(err.message);
          break;
        case "auth/wrong-password":
          setpasswordError(err.message);
          break;
      }
    });
  };

  const handleLogout = () => {
    auth.signOut();
  };

  const authListner = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        clearInputs();
        setuser(user);
      } else {
        setuser("");
      }
    });
  };

  useEffect(() => {
    authListner();
  }, []);

  return (
    <>
      <section className="firstDiv projects">
        <Container>
          {user ? (
            <>
              <div className="headerTags">
                <div>
                  <h2 className="section__title">Project Request Data</h2>
                  <h2 className="section__subtitle mt-2">
                    Hello Jignesh, Your client's project request list
                  </h2>
                </div>

                <button className="sub__btn" onClick={handleLogout}>
                  Sign Out
                </button>
              </div>

              <div className="dropdown-divider my-5"></div>

              <ul className="jprojList">
                {projData !== undefined &&
                  projData.map(({ name, email, msg }, i) => (
                    <li key={i}>
                      <h2 className="heading-4">
                        Project By : <span>{name}</span>
                      </h2>
                      <div className="dropdown-divider my-3"></div>
                      <a href={`mailto:${email}`} className="href">
                        {email}
                      </a>

                      <h2 className="heading-6 mt-4 mb-2">Project Details :</h2>

                      <h2 className="subheading">{msg}</h2>
                    </li>
                  ))}
              </ul>
            </>
          ) : (
            <>
              <h2 className="section__title">Project Request Data</h2>
              <h2 className="section__subtitle mt-2">Login to continue</h2>
              <div className="loginContainer col-md-6 mt-5 p-0">
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="form-control"
                  required
                  autoFocus
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
                <p className="errorMsg">{emailError}</p>

                <input
                  type="password"
                  name="password"
                  id="pass"
                  placeholder="Password"
                  required
                  className="form-control"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />

                <p className="errorMsg">{passwordError}</p>

                <button className="sub__btn" onClick={handleLogin}>
                  Sign In
                </button>
              </div>
            </>
          )}
        </Container>
      </section>
    </>
  );
}
