import React from "react";
import "./Partner.scss";
import { Container } from "react-bootstrap";

import aos from "aos";
import "aos/dist/aos.css";

export default function Partner() {
  aos.init();
  return (
    <>
      <section className="partner">
        <img src="img/brand/logo.png" className="jp_logo" alt="logo" />
        <Container>
          <div className="main_logo">
            <img
              src="img/brand/logo.svg"
              alt="jposarekar"
              className="jp__partner"
            />
          </div>
        </Container>
      </section>
    </>
  );
}
