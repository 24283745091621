import React from "react";
import "./ProjBox.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import aos from "aos";
import "aos/dist/aos.css";

export default function ProjBox(props) {
  aos.init();
  return (
    <>
      <div
        className="projectBox"
        data-aos="fade-down"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos-once="true"
      >
        <div className="project__image">
          <LazyLoadImage
            alt="project_img"
            width="100%"
            height="100%"
            effect="opacity"
            delayTime="500"
            src={props.img}
          />
        </div>
        <div className="project__title">
          <h2 className="subheading">
            {props.title}
            <span>{props.description}</span>
          </h2>
        </div>
      </div>

      {/* <div className="projectBox">
        <div className="project__image">
          <img src={props.img} alt="project_image" loading="lazy" />
        </div>
        <div className="project__title">
          <h2 className="subheading">
            {props.title}
            <span>{props.description}</span>
          </h2>
        </div>
      </div> */}
    </>
  );
}
