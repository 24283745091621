import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, Collapse } from "react-bootstrap";
import "./Header.scss";

export default function Header() {
  const [toggleMenu, settoggleMenu] = useState(false);

  const toggle = () => settoggleMenu(!toggleMenu);

  return (
    <>
      <Navbar expand="md">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src="img/brand/logo.png" alt="logo" />
            jposarekar
          </Navbar.Brand>
          <Navbar.Toggle
            className={`${toggleMenu ? "btnMenu02 is-open" : "btnMenu02"}`}
            onClick={toggle}
            aria-controls="basic-navbar-nav"
            aria-expanded={toggleMenu}
          >
            <span>
              <span></span>
            </span>
          </Navbar.Toggle>

          <Collapse
            id="basic-navbar-nav"
            className="navbar-collapse "
            in={toggleMenu}
          >
            <Nav>
              <Nav.Link disabled></Nav.Link>
              <Nav.Link onClick={toggle} as={Link} to="/work">
                <span data-hover="Work">Work</span>
              </Nav.Link>
              <Nav.Link disabled>
                <p>Get more details about my work</p>
              </Nav.Link>

              <Nav.Link onClick={toggle} as={Link} to="/about" id="about">
                <span data-hover="About">About</span>
              </Nav.Link>
              <Nav.Link disabled>
                <p className="mb-0">Get more details about me</p>
              </Nav.Link>
              <Nav.Link disabled></Nav.Link>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}
