import React from "react";
import { Container, Row } from "react-bootstrap";
import "./FeatWork.scss";
import { Alink } from "../../../components/Hlink/Hlink";
import ProjBox from "../../../components/ProjBox/ProjBox";
import aos from "aos";
import "aos/dist/aos.css";
import Skeleton from "react-loading-skeleton";

export default function FeatWork(props) {
  aos.init();

  return (
    <>
      <section className="featwork">
        <Container>
          <h2
            className="section__title"
            data-aos="flip-up"
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
          >
            The work I do
          </h2>

          <div className="work__wrapper">
            {props.workdata !== undefined ? (
              props.workdata
                .slice(0, 4)
                .map(({ id, img, title, description }) => (
                  <ProjBox
                    key={id}
                    img={img}
                    title={title}
                    description={description}
                  />
                ))
            ) : (
              <>
                <Skeleton delay={3} width={"100%"} height={300} />
                <Skeleton delay={3} width={"100%"} height={300} />
                <Skeleton delay={3} width={"100%"} height={300} />
                <Skeleton delay={3} width={"100%"} height={300} />
              </>
            )}
          </div>

          <Row className="justify-content-center projAll_btn">
            <Alink title="View All Projects" link="/work" />
          </Row>
        </Container>
      </section>
    </>
  );
}
