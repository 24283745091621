import React from "react";
import "./HomeProcess.scss";
import { Container } from "react-bootstrap";
import { Alink } from "../../../components/Hlink/Hlink";
import aos from "aos";
import "aos/dist/aos.css";

export default function HomeProcess() {
  aos.init();
  return (
    <>
      <section className="homeProcess">
        <div className="homeProcess__background">
          <img
            src="img/backgrounds/homeProcess.webp"
            loading="lazy"
            alt="homeProcess"
          />
        </div>
        <div className="homeProcess__content">
          <Container>
            <div
              className="textHead"
              data-aos="fade-down"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
            >
              <h2 className="section__subtitle">What I do</h2>
              <h2 className="section__title">Going beyond what's possible</h2>
            </div>

            <ul className="homeProcess__list">
              <li>
                <h2 className="heading-3">Strategy</h2>
              </li>

              <li>
                <h2 className="heading-3">Design</h2>
              </li>

              <li>
                <h2 className="heading-3">Development</h2>
              </li>
            </ul>

            <Alink title="Learn More" link="/about" />
          </Container>
        </div>
      </section>
    </>
  );
}
