import React from "react";
import { Container } from "react-bootstrap";
import "./AboutBts.scss";

export default function AboutBts() {
  return (
    <>
      <section className="aboutBts">
        <Container>
          <div
            className="titleHead"
            data-aos="flip-up"
            data-aos-easing="ease-in-sine"
            data-aos-offset="300"
            data-aos-once="true"
          >
            <h2 className="section__title">Behind the scenes</h2>
            <h2 className="subheading mt-2">
              I'm addicted to minimalist design and clean coding
            </h2>
          </div>

          <div className="bts__wrapper">
            <div className="imgBlock img1">
              <img
                src="img/backgrounds/bts__ui.webp"
                loading="lazy"
                alt="bts_jposarekar"
              />
            </div>

            <div className="imgBlock img2">
              <img
                src="img/backgrounds/bts__plan.webp"
                loading="lazy"
                alt="bts_jposarekar"
              />
            </div>

            <div className="imgBlock img3">
              <img
                src="img/backgrounds/bts__ux.webp"
                loading="lazy"
                alt="bts_jposarekar"
              />
            </div>

            <div className="imgBlock img4">
              <img
                src="img/backgrounds/homeProcess.webp"
                alt="bts_jposarekar"
                loading="lazy"
              />
            </div>

            <div className="imgBlock img5">
              <img
                src="img/backgrounds/bts__debug.webp"
                loading="lazy"
                alt="bts_jposarekar"
              />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
