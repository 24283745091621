import React from "react";
import { Container } from "react-bootstrap";
import "./Footer.scss";
import { Hlink } from "../Hlink/Hlink";

export default function Footer() {
  return (
    <footer>
      <Container>
        <div className="footer__wrapper">
          <div className="footer__context">
            <h2 className="heading-3">Got a project?</h2>
            <Hlink link="/contact" title="Let's connect" />
          </div>

          <div className="footer__social">
            <a
              className="href"
              href="https://www.instagram.com/jposarekar/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              className="href"
              href="https://www.facebook.com/jposarekar"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
            <a
              className="href"
              href="https://twitter.com/Jignesh070"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
            <a
              className="href"
              href="https://www.linkedin.com/in/jposarekar/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
            <a
              className="href"
              href="https://www.youtube.com/JigneshPosarekar"
              target="_blank"
              rel="noreferrer"
            >
              Youtube
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
}
