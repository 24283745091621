import React from "react";
import { Hlink } from "../../../components/Hlink/Hlink";
import "./PageBanner.scss";

export default function PageBanner() {
  return (
    <>
      <div className="pageBanner">
        <div className="workBanner">
          <img src="img/backgrounds/homeProcess.webp" alt="work" />

          <div className="text__frame">
            <h2 className="heading-4">Work</h2>
            <h2 className="body-large">
              Take a look at what I’ve created and get inspired
            </h2>

            <Hlink title="View Work" link="/work" />
          </div>
        </div>
        <div className="aboutBanner">
          <img src="img/brand/jposarekar.jpeg" alt="Jignesh Posarekar" />

          <div className="text__frame">
            <h2 className="heading-4">About</h2>
            <h2 className="body-large">Learn more about me & what I do best</h2>

            <Hlink title="View About" link="/about" />
          </div>
        </div>
      </div>
    </>
  );
}
