import React from "react";
import "./HomeBanner.scss";

import { Container } from "react-bootstrap";
import { Alink } from "../../../components/Hlink/Hlink";
import Typewriter from "@vvo/typewriter-effect";
import aos from "aos";
import "aos/dist/aos.css";

export default function HomeBanner() {
  aos.init();

  return (
    <>
      <section className="home__banner">
        <Container>
          <div className="banner__wrapper">
            <div className="banner__textarea">
              <Typewriter
                options={{
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .changeDeleteSpeed(2)
                    .typeString("I'm Front-End Developer")
                    .pauseFor(1500)
                    .deleteChars(19)
                    .changeDeleteSpeed(2)
                    .typeString("UI Designer")
                    .pauseFor(1500)
                    .deleteAll()
                    .changeDeleteSpeed(2)
                    .typeString("The UX Engineer")
                    .pauseFor(3000)
                    .deleteAll()

                    .start();
                }}
              />
              <h2 className="heading-2" data-aos="flip-up" data-aos-once="true">
                I design and code beautifully simple things, and I love what I
                do.
              </h2>
              <Alink title="Connect with me" link="/contact" />
            </div>

            <div className="banner__imagearea">
              <img
                src="img/brand/jignesh.png"
                alt="Jignesh Posarekar"
                className="hero__img"
                loading="lazy"
              />
              <img
                src="img/brand/logo__white.png"
                alt="jposarekar"
                className=" brand__img"
                loading="lazy"
              />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
