import React from "react";
import { Link } from "react-router-dom";
import "./Hlink.scss";
import { IoArrowForwardOutline } from "react-icons/io5";

export function Hlink(props) {
  var currentLink = props.link;
  return (
    <>
      <Link to={currentLink} className="hlink">
        {props.title}
      </Link>
    </>
  );
}

export function Alink(props) {
  var currentLink = props.link;
  return (
    <>
      <Link to={currentLink} className="hlink">
        {props.title}

        <IoArrowForwardOutline />
      </Link>
    </>
  );
}
