import React from "react";
import { Container } from "react-bootstrap";
import "./AboutApproach.scss";

export default function AboutApproach() {
  return (
    <>
      <section className="abtApproach">
        <Container>
          <div
            className="titleHead"
            data-aos="flip-up"
            data-aos-easing="ease-in-sine"
            data-aos-offset="300"
            data-aos-once="true"
          >
            <h2 className="section__subtitle">My approach</h2>

            <h2 className="section__title">
              Steps for successful design experience
            </h2>
          </div>

          <ul className="approachList">
            <li>
              <div
                className="imgBox"
                style={{
                  backgroundImage: "url(img/backgrounds/abt_understand.svg)",
                }}
              ></div>
              <h2 className="heading-3">Understand</h2>
              <h2 className="body-large">
                Before I jump in, I'd like to learn about your unique business,
                goals and your core values. Then I understand why we need to
                collaborate and help you reach your goal.
              </h2>
            </li>

            <li>
              <div
                className="imgBox"
                style={{
                  backgroundImage: "url(img/backgrounds/abt_brainstrom.svg)",
                }}
              ></div>
              <h2 className="heading-3">Brainstrom</h2>
              <h2 className="body-large">
                The next process is I come up with ideas that can solve your
                problems and make your customers fall in love with your work.
              </h2>
            </li>

            <li>
              <div
                className="imgBox"
                style={{
                  backgroundImage: "url(img/backgrounds/abt_design.svg)",
                }}
              ></div>
              <h2 className="heading-3">Design</h2>
              <h2 className="body-large">
                Later, I apply these ideas in life. Where I’ll help you shape
                your product and visualize what you wanted.
              </h2>
            </li>

            <li>
              <div
                className="imgBox"
                style={{
                  backgroundImage: "url(img/backgrounds/abt_improve.svg)",
                }}
              ></div>
              <h2 className="heading-3">Improve</h2>
              <h2 className="body-large">
                I always look for ways to improve what I do. That’s why I take
                an iterative approach to my work and my practice.
              </h2>
            </li>
          </ul>
        </Container>
      </section>
    </>
  );
}
