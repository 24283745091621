import firebase from "firebase";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBJqys-eKiiTKDKJKF8SGbor2uND-doxHU",
  authDomain: "jposarekar-6f041.firebaseapp.com",
  databaseURL: "https://jposarekar-6f041-default-rtdb.firebaseio.com",
  projectId: "jposarekar-6f041",
  storageBucket: "jposarekar-6f041.appspot.com",
  messagingSenderId: "777421722633",
  appId: "1:777421722633:web:f68b7d543c6fb0fcf954d8",
  measurementId: "G-EG74Y3X4J3",
});

const db = firebaseApp.database();

const analytics = firebaseApp.analytics();

const auth = firebase.auth();

export { db, analytics, auth };
