import React from "react";
import { Container, Table } from "react-bootstrap";
import "./AboutDetails.scss";
import Skeleton from "react-loading-skeleton";

export default function AboutDetails(props) {
  return (
    <>
      <section className="aboutDetails">
        <div className="aboutDetails__background">
          <img
            src="img/backgrounds/about__soln.webp"
            loading="lazy"
            alt="aboutImg"
          />
        </div>

        <div className="aboutDetails__content">
          <Container>
            <h2
              className="section__title"
              data-aos="fade-down"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
            >
              Simple, useful and beautiful solutions
            </h2>

            <div className="tableWrapper">
              <Table hover>
                <thead>
                  <tr>
                    <th>Design</th>
                  </tr>
                </thead>
                <tbody>
                  {props.design !== undefined ? (
                    props.design.map(({ value, id }) => (
                      <tr key={id}>
                        <td>{value}</td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <Skeleton width={"100%"} height={50} />
                      <Skeleton width={"100%"} height={50} />
                      <Skeleton width={"100%"} height={50} />
                      <Skeleton width={"100%"} height={50} />
                    </>
                  )}
                </tbody>
              </Table>

              <Table hover>
                <thead>
                  <tr>
                    <th>Development</th>
                  </tr>
                </thead>
                <tbody>
                  {props.development !== undefined ? (
                    props.development.map(({ value, id }) => (
                      <tr key={id}>
                        <td>{value}</td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <Skeleton width={"100%"} height={50} />
                      <Skeleton width={"100%"} height={50} />
                      <Skeleton width={"100%"} height={50} />
                      <Skeleton width={"100%"} height={50} />
                    </>
                  )}
                </tbody>
              </Table>
            </div>

            <h2 className="section__subtitle">I EXPERTIZE IN</h2>

            <div className="exp_logo">
              {props.expert !== undefined ? (
                props.expert.map(({ id, img, alt }) => (
                  <img key={id} src={img} alt={alt} />
                ))
              ) : (
                <>
                  <Skeleton width={"100%"} height={50} />
                  <Skeleton width={"100%"} height={50} />
                  <Skeleton width={"100%"} height={50} />
                  <Skeleton width={"100%"} height={50} />
                </>
              )}
            </div>
          </Container>
        </div>
      </section>
    </>
  );
}
